import GoogleButton from "react-google-button";
import { sendGet } from "./axios";

function Test() {
  const getLoginUrl = async () => {
    // await fetch(`http://localhost:8080/auth/login`, {
    //   method: "GET",
    //   credentials: "include",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // }).then((res) => console.log(res));
    sendGet(`http://103.171.91.85/api/auth/login`).then((res) => {
      window.location.href = res.data.urlLogin;
    });
  };
  return <GoogleButton onClick={getLoginUrl} />;
}
export default Test;
